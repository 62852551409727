import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Container } from '@jsluna/grid'
import { Portal, childMatchesType } from '@jsluna/utils'

import HeaderItem from './HeaderItem'
import HeaderSearch from './HeaderSearch'
import HeaderNav from './HeaderNav'
import HeaderActions from './HeaderActions'
import HeaderTabBar from './HeaderTabBar'

class HeaderMainBar extends Component {
  constructor(props) {
    super(props)
    const { onToggle } = props

    this.state = {
      hasBottomBar: false,
      hasTabBar: [],
      hasDrawer: false,
    }

    this.parseChildren = this.parseChildren.bind(this)

    this.closeDrawer = e => onToggle(e, false)
  }

  componentDidMount() {
    this.parseChildren()
  }

  componentDidUpdate(prevProps) {
    const { children } = this.props

    if (children !== prevProps.children) {
      this.parseChildren()
    }
  }

  parseChildren() {
    const { children } = this.props

    const hasTabBar = []
    let hasBottomBar = false
    let hasDrawer = false

    React.Children.forEach(children, child => {
      if (
        childMatchesType(child, [
          HeaderItem,
          HeaderSearch,
          HeaderNav,
          HeaderActions,
        ])
      ) {
        if (child.props.tabBar && !hasTabBar.includes(child.props.tabBar)) {
          hasTabBar.push([child.props.tabBar, child.props.tabBarSoft])
        }

        if (child.props.bottomBar) {
          hasBottomBar = true
        }

        if (child.props.drawer) {
          hasDrawer = true
        }
      } else if (childMatchesType(child, HeaderTabBar)) {
        hasTabBar.push(true)
      }
    })

    this.setState({
      hasTabBar,
      hasBottomBar,
      hasDrawer,
    })
  }

  render() {
    const { hasBottomBar, hasTabBar, hasDrawer } = this.state
    const {
      children,
      className,
      onToggle,
      isSticky,
      isStuck,
      hasGlobalBar: forceGlobalBar,
      hasBottomBar: forceBottomBar,
      hasTabBar: forceTabBar,
      hasDrawer: forceDrawer,
      ...rest
    } = this.props

    return (
      <Fragment>
        <Container
          className={classnames('ln-c-header__main-bar', className)}
          soft
          {...rest}
        >
          {hasDrawer && (
            <button
              className="ln-c-header__toggle ln-c-header-toggle"
              type="button"
              onClick={onToggle}
            >
              <span className="ln-c-header-toggle__item" />
              <span className="ln-u-visually-hidden">Menu</span>
            </button>
          )}
          {React.Children.map(children, child =>
            React.isValidElement(child)
              ? React.cloneElement(child, { closeDrawer: this.closeDrawer })
              : child,
          )}
        </Container>

        <Portal prepend exclusive id="ln-header-spacer" selector=".ln-o-page">
          {!isSticky && (
            <div
              aria-hidden
              className="ln-c-header__spacer ln-c-header__spacer--collapsed"
            >
              &nbsp;
            </div>
          )}
          {isSticky && forceGlobalBar && (
            <div
              aria-hidden
              className={classnames(
                'ln-c-header__spacer',
                'ln-c-header__spacer--global-bar',
                isStuck && 'is-stuck',
              )}
            >
              &nbsp;
            </div>
          )}
          {forceTabBar && isSticky && (
            <div
              className={classnames(
                'ln-c-header__spacer',
                forceTabBar === true
                  ? 'ln-c-header__spacer--tab-bar'
                  : `ln-c-header__spacer--tab-bar@${forceTabBar}`,
              )}
            >
              &nbsp;
            </div>
          )}
          {hasTabBar.length && isSticky ? (
            hasTabBar.map(([tab, soft]) => (
              <div
                key={tab.toString()}
                className={classnames(
                  'ln-c-header__spacer',
                  Array.isArray(tab)
                    ? tab.map(tabType => [
                        tab === true
                          ? 'ln-c-header__spacer--tab-bar'
                          : `ln-c-header__spacer--tab-bar@${tabType}`,
                        soft && 'ln-c-header__spacer--tab-bar-soft',
                      ])
                    : [
                        tab === true
                          ? 'ln-c-header__spacer--tab-bar'
                          : `ln-c-header__spacer--tab-bar@${tab}`,
                        soft && 'ln-c-header__spacer--tab-bar-soft',
                      ],
                )}
              >
                &nbsp;
              </div>
            ))
          ) : (
            <div
              className={
                classnames(
                  hasTabBar.map(
                    tab =>
                      (tab === 'max-nav' || tab === true) &&
                      'ln-c-header__spacer ln-c-header__spacer--tab-bar@max-nav',
                  ),
                ) || 'ln-u-display-none'
              }
            >
              &nbsp;
            </div>
          )}
        </Portal>

        {(hasBottomBar || forceBottomBar) && (
          <Portal
            exclusive
            id="ln-header-bottom-bar-spacer"
            selector=".ln-o-page"
          >
            <div
              hidden
              aria-hidden
              className="ln-c-header__spacer ln-c-header__spacer--bottom-bar"
            >
              &nbsp;
            </div>
          </Portal>
        )}
      </Fragment>
    )
  }
}

HeaderMainBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Function to open and close the drawer menu on small screens */
  onToggle: PropTypes.func,
  /** Whether the header should stick to the top of the page */
  isSticky: PropTypes.bool,
  /** Flags if the user has scrolled to trigger style changes to header */
  isStuck: PropTypes.bool,
  /** Flag to force knowledge of global bar for correct spacing if fixed, should be detectable in most circumstances */
  hasGlobalBar: PropTypes.bool,
  /** Flag to force knowledge of bottom bar for correct spacing if fixed, should be detectable in most circumstances */
  hasBottomBar: PropTypes.bool,
  /** Flag to force knowledge of tab bar for correct spacing if fixed, should be detectable in most circumstances */
  hasTabBar: PropTypes.bool,
  /** Flag to force knowledge of drawer items to display toggle button, should be detectable in most circumstances */
  hasDrawer: PropTypes.bool,
}

HeaderMainBar.defaultProps = {
  children: undefined,
  className: undefined,
  onToggle: undefined,
  isSticky: false,
  isStuck: false,
  hasGlobalBar: false,
  hasBottomBar: false,
  hasTabBar: false,
  hasDrawer: false,
}

HeaderMainBar.displayName = 'HeaderMainBar'

export default HeaderMainBar
