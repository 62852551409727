import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { debounce, filterProps } from '@jsluna/utils'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage,
} from '@jsluna/progress'

import AutocompleteField from './AutocompleteField'
import createFilterDefault from './createFilter'

const LoadingMessage = () => (
  <ProgressIndicator loading preventFocus>
    <ProgressSpinner />
    <ProgressMessage className="ln-u-margin-left">Loading...</ProgressMessage>
  </ProgressIndicator>
)

LoadingMessage.displayName = 'LoadingMessage'

class AsyncAutocompleteField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [],
      loading: false,
      inputValue: '',
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.getOptions = debounce(this.getOptions.bind(this), props.debounceWait)
    this.getNoResultsMessage = this.getNoResultsMessage.bind(this)
    this.resetOptions = this.resetOptions.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidUpdate(_, prevState) {
    const { minChars } = this.props
    const { inputValue } = this.state
    const { inputValue: prevInputValue } = prevState

    if (inputValue !== prevInputValue) {
      if (inputValue.length < minChars) {
        this.resetOptions()
        return
      }
      this.getOptions(inputValue)
    }
  }

  componentWillUnmount() {
    if (this.getOptions) {
      this.getOptions.cancel()
    }
  }

  getOptions() {
    const { loadOptions } = this.props
    const { inputValue } = this.state

    this.setState({
      loading: true,
    })

    loadOptions(inputValue).then(options => {
      this.setState({
        options,
        loading: false,
      })
    })
  }

  getNoResultsMessage() {
    const { inputValue, loading } = this.state
    const {
      minChars,
      placeholderMessage,
      loadingMessage,
      noResultsMessage,
    } = this.props

    if (inputValue.length < minChars) {
      return placeholderMessage
    }

    if (loading) {
      return loadingMessage
    }

    return noResultsMessage
  }

  handleInputChange(e) {
    const { onChange } = this.props
    const { value } = e.target

    this.setState({
      inputValue: value,
    })

    if (onChange) {
      onChange(e)
    }
  }

  handleSelect(e) {
    const { onSelect } = this.props

    if (onSelect) {
      onSelect(e)
    }
  }

  resetOptions() {
    this.setState({
      options: [],
    })
  }

  render() {
    const { inputValue, options } = this.state
    const { children } = this.props

    const props = {
      ...filterProps(this.props, [
        'loadOptions',
        'debounceWait',
        'children',
        'minChars',
        'placeholderMessage',
        'loadingMessage',
      ]),
      inputValue,
      async: true,
      options,
      onChange: this.handleInputChange,
      noResultsMessage: this.getNoResultsMessage(),
      onSelect: this.handleSelect,
    }

    if (children) {
      return React.cloneElement(React.Children.only(children), props)
    }

    return <AutocompleteField {...props} />
  }
}

AsyncAutocompleteField.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  minChars: PropTypes.number,
  debounceWait: PropTypes.number,
  children: PropTypes.node,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholderMessage: PropTypes.node,
  loadingMessage: PropTypes.node,
  noResultsMessage: PropTypes.node,
  filter: PropTypes.func,
}

AsyncAutocompleteField.defaultProps = {
  minChars: 2,
  debounceWait: 200,
  children: undefined,
  onChange: undefined,
  onSelect: undefined,
  placeholderMessage: 'Start typing to search',
  loadingMessage: <LoadingMessage />,
  noResultsMessage: undefined,
  filter: createFilterDefault,
}

AsyncAutocompleteField.displayName = 'AsyncAutocompleteField'

export default AsyncAutocompleteField
