import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'

import FormGroup from './FormGroup'

import { getOptionLabel as getOptionLabelDefault } from './getOptionProperties'
import createFilterDefault from './createFilter'

const AutocompleteField = ({
  element: Element,
  onSelect,
  defaultSelectedOption,
  getOptionLabel,
  value,
  spellcheck,
  disabled,
  filter,
  ...props
}) => (
  <Downshift
    itemToString={getOptionLabel}
    onChange={onSelect}
    inputValue={value}
    defaultSelectedItem={defaultSelectedOption}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem,
      getToggleButtonProps,
      clearSelection,
    }) => (
      <Element>
        <FormGroup
          getInputProps={getInputProps}
          getItemProps={getItemProps}
          getLabelProps={getLabelProps}
          getMenuProps={getMenuProps}
          isOpen={isOpen}
          inputValue={inputValue}
          highlightedIndex={highlightedIndex}
          selectedItem={selectedItem}
          getToggleButtonProps={getToggleButtonProps}
          clearSelection={clearSelection}
          getOptionLabel={getOptionLabel}
          value={value}
          disabled={disabled}
          filter={filter}
          spellcheck={spellcheck}
          {...props}
        />
      </Element>
    )}
  </Downshift>
)

AutocompleteField.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  defaultSelectedOption: PropTypes.shape({}),
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  getOptionLabel: PropTypes.func,
  onSelect: PropTypes.func,
  spellcheck: PropTypes.bool,
  value: PropTypes.string,
}

AutocompleteField.defaultProps = {
  element: 'div',
  defaultSelectedOption: undefined,
  disabled: false,
  filter: createFilterDefault,
  getOptionLabel: getOptionLabelDefault,
  onSelect: undefined,
  spellcheck: true,
  value: undefined,
}

AutocompleteField.displayName = 'AutocompleteField'

export default AutocompleteField
